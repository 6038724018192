import React from "react";
import Hero from "../../modules/hero";
import HeroImage from "../../images/made-by-god-hero.webp";

import { AnimatePresence, motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const gradientVariants = {
  visible: {
    opacity: 0.6,
    scale: 2,
    transition: {
      duration: 2,
      repeat: Infinity,
      repeatType: "reverse",
      repeatDelay: 1,
    },
  },
};

const PortraitHome = ({ display, setPlay, setPlayTrailer }) => {
  return (
    <div className="relative h-full w-full ">
      <div className="absolute z-30 bottom-0  w-screen opacity-90">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.8, transition: { duration: 2 } }}
            className="w-full h-full flex justify-center relative"
          >
            <LazyLoadImage
              effect="blur"
              src={HeroImage}
              alt="Hero for Made By God Film"
              className="min-h-[75vh] max-h-screen min-w-[75vh] object-cover"
            />
          </motion.div>
        </AnimatePresence>
      </div>
      <motion.div
        initial={{ opacity: 0.2, scale: 1.5 }}
        animate="visible"
        variants={gradientVariants}
        className="absolute z-40 h-full w-full  mix-blend-soft-screen"
        style={{
          background:
            "linear-gradient(90deg, rgba(63, 69, 200, 0.05) 0%, rgba(111, 45, 138, 0.16) 55%, rgba(148, 0, 27, 0.20) 100%)",
        }}
      ></motion.div>

      <div className="h-full absolute z-50 top-0 p-3 py-5 xl:p-8 w-full">
        <Hero
          display={display}
          setPlay={setPlay}
          setPlayTrailer={setPlayTrailer}
        />
      </div>
    </div>
  );
};

export default PortraitHome;
