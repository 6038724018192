import React from "react";
import Title from "../images/made-by-god-title.webp";
import FFF from "../images/FFF.webp";
import SWFF from "../images/SWFF.webp";
import { AnimatePresence, motion } from "framer-motion";
import PlayButton from "./playbutton";
import HeroBody from "./herobody";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PlayTrailerButton from "./playtrailerbutton";

const Hero = ({ display, setPlay, setPlayTrailer }) => {
  return (
    <div className="h-full relative w-full z-[70] portrait:flex portrait:flex-col portrait:items-center hero-text-shadow">
      <div className="min-h-[45vh] fade-in">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 2 } }}
            className="max-h-[45vh]"
          >
            <img
              src={Title}
              alt="Made By God Logo"
              className="max-h-[45vh] fade-in"
            />
          </motion.div>
        </AnimatePresence>
      </div>

      <div
        id="landscape-hero-body"
        className="portrait:hidden fade-in text-xs md:text-base lg:text-lg xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-4xl font-light opacity-90  sm:leading-[.85rem] lg:leading-[1.4rem] 2xl:leading-7 2xl:max-w-xl 3xl:max-w-3xl  4xl:max-w-6xl  portrait:mb-0"
      >
        <HeroBody display={display} />
      </div>
      <div
        id="portrait-hero-body"
        className=" p-2 lg:p-4 landscape:hidden fade-in my-2 lg:my-4 3xl:my-8 4xl:my-10 mx-auto container max-w-lg text-sm sm:text-lg md:text-2xl  lg:text-3xl xl:text-3xl 2xl:text-4xl 3xl:text-4xl 4xl:text-5xl font-light opacity-90  sm:leading-[1.2rem] lg:leading-tight lg:max-w-2xl 2xl:max-w-2xl 3xl:max-w-3xl  4xl:max-w-6xl portrait:mb-0"
      >
        <HeroBody display={display} />
      </div>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 2 } }}
          className=""
        >
          <div className="sm:flex sm:flex-row-reverse sm:gap-4">
            <PlayButton setPlay={setPlay} />
            <PlayTrailerButton setPlayTrailer={setPlayTrailer} />
          </div>
        </motion.div>
      </AnimatePresence>

      {display === "Home" && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 2 } }}
          >
            <div className="landscape:hidden">
              <div
                id="laurels-portrait"
                className=" flex opacity-90 justify-between bottom-2 md:bottom-4 portrait:left-0 h-16 xxs:h-24 md:h-32 2xl:h-56 3xl:h-60 4xl:h-64 absolute w-full max-w-xs 2xl:max-w-xl 3xl:max-w-3xl 4xl:max-w-5xl portrait:max-w-none"
              >
                <LazyLoadComponent>
                  <LazyLoadImage
                    effect="blur"
                    src={SWFF}
                    alt="Sidewalk Film Festival Laurel"
                    className="h-16 xxs:h-24 md:h-32 2xl:h-56 3xl:h-60 4xl:h-64 w-auto"
                  />
                  <LazyLoadImage
                    effect="blur"
                    src={FFF}
                    alt="Florida Film Festival Laurel"
                    className="h-16 xxs:h-24 md:h-32 2xl:h-56 3xl:h-60 4xl:h-64 w-auto"
                  />
                </LazyLoadComponent>
              </div>
            </div>
            <div className="portrait:hidden">
              <div
                id="laurels-landscape"
                className="flex opacity-90 justify-between bottom-0 h-12 md:h-16 lg:h-20 xl:h-24 2xl:h-32 3xl:h-40 4xl:h-56 absolute w-full max-w-xs lg:max-w-md xl:max-w-lg 2xl:max-w-xl 3xl:max-w-3xl 4xl:max-w-5xl portrait:max-w-none"
              >
                <LazyLoadComponent>
                  <LazyLoadImage
                    effect="blur"
                    height={"100%"}
                    src={SWFF}
                    alt="Sidewalk Film Festival Laurel"
                    className="h-12 md:h-16 lg:h-20 xl:h-24 2xl:h-32 3xl:h-40 4xl:h-56 w-auto"
                  />
                  <LazyLoadImage
                    effect="blur"
                    src={FFF}
                    alt="Florida Film Festival Laurel"
                    className="h-12 md:h-16 lg:h-20 xl:h-24 2xl:h-32 3xl:h-40 4xl:h-56 w-auto"
                  />
                </LazyLoadComponent>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
};

export default Hero;
