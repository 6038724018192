import React from "react";
import Hero from "../../modules/hero";

const LandscapeHome = ({ display, setPlay, setPlayTrailer }) => {
  return (
    <div className="absolute z-50 h-full w-full p-4 lg:p-8 xl:p-12 3xl:py-16 4xl:py-24">
      <div className="max-w-sm sm:max-w-sm lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl 3xl:max-w-4xl 4xl:max-w-6xl h-full ">
        <Hero
          display={display}
          setPlay={setPlay}
          setPlayTrailer={setPlayTrailer}
        />
      </div>
    </div>
  );
};

export default LandscapeHome;
