import React, { useState } from "react";
import HeroImage from "../../images/made-by-god-hero.webp";
import HeroImageMobile from "../../images/made-by-god-hero-mobile.webp";
import Grain from "../../images/dark-concrete-texture-background.webp";
import GrainMobile from "../../images/dark-concrete-texture-background-mobile.webp";
import PortraitHome from "./portraithome";
import LandscapeHome from "./landscapehome";
import { AnimatePresence, motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Home = ({ setPlay, setPlayTrailer }) => {
  const [display, setDisplay] = useState("Home");
  return (
    <div
      className="bg-theme-black-500 w-screen h-screen font-Trebuchet text-theme-white-500 
    relative overflow-hidden"
    >
      <div className="absolute z-20 min-w-screen w-full h-full bg-theme-black-500 portrait:hidden">
        <div className="container mx-auto top-0 right-0 flex justify-end ">
          <AnimatePresence>
            <motion.div className="h-auto max-w-[60vw] contrast-[1.1]">
              <LazyLoadImage
                src={HeroImage}
                alt="Hero for Made By God Film"
                effect="blur"
                className="hidden sm:block object-contain max-h-screen"
              />

              <LazyLoadImage
                src={HeroImageMobile}
                alt="Hero for Made By God Film"
                className="sm:hidden object-contain max-h-screen"
              />
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
      <img
        src={Grain}
        alt="Grain texture"
        className="hidden lg:block absolute z-30 h-full w-full mix-blend-screen opacity-20 contrast-[1.2]"
      />
      <img
        src={GrainMobile}
        alt="Grain texture"
        className="lg:hidden absolute z-30 h-full w-full mix-blend-screen opacity-20 contrast-[1.2]"
      />
      <div
        className="h-full w-full  mix-blend-overlay opacity-30 absolute z-40 change-width"
        style={{
          background:
            "linear-gradient(90deg, rgba(63, 69, 200, 0.00) 0%, rgba(99, 150, 190, 0.55) 31.77%, rgba(111, 45, 138, 0.42) 68.23%, rgba(148, 0, 27, 0.81) 100%)",
        }}
      ></div>

      <div className="absolute z-[90] bottom-1 right-1 md:bottom-2 md:right-2 lg:right-5 text-xs lg:text-sm portrait:text-[.6rem] portrait:leading-none font-light opacity-30 xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 2xl:right-10 4xl:right-20">
        <div className="flex flex-col">
          <p>© 2023 Wyatt Weymouth. All rights reserved.</p>
          <a
            href="https://www.wamwebdev.com/"
            rel="noopener noreferrer"
            target="_blank"
            className="text-xs portrait:text-[.5rem] portrait:h-[.5rem] portrait:leading-none  w-full text-right"
          >
            Website Created By: Wright Angle Media
          </a>
        </div>
      </div>
      <div className="relative flex justify-center h-full w-full  z-50 ">
        <div className="absolute z-[60] top-0 right-2 flex  gap-x-4 2xl:gap-x-8 3xl:gap-x-10 4xl:gap-x-12 text-xs lg:text-sm xl:text-lg 3xl:text-2xl 4xl:text-4xl font-light lg:right-5 2xl:right-10 4xl:right-20 portrait:mt-2  mt-4 lg:mt-8 xl:mt-12 3xl:mt-16 4xl:mt-24 ">
          {display !== "Home" && (
            <div
              className="cursor-pointer hover:text-theme-red-500 opacity-80 hover:opacity-100"
              onClick={() => setDisplay("Home")}
            >
              HOME
            </div>
          )}
          {display !== "Credits" && (
            <div
              className="cursor-pointer hover:text-theme-red-500 opacity-80 hover:opacity-100"
              onClick={() => setDisplay("Credits")}
            >
              CREDITS
            </div>
          )}
          {display !== "Description" && (
            <div
              className="cursor-pointer hover:text-theme-red-500 opacity-80 hover:opacity-100"
              onClick={() => setDisplay("Description")}
            >
              SYNOPSIS
            </div>
          )}
        </div>

        <div className="w-full h-full  mx-auto container">
          <div className="portrait:hidden w-full h-full">
            <LandscapeHome
              display={display}
              setPlay={setPlay}
              setPlayTrailer={setPlayTrailer}
            />
          </div>
          <div className="landscape:hidden w-full h-full">
            <PortraitHome
              display={display}
              setPlay={setPlay}
              setPlayTrailer={setPlayTrailer}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
