import { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/home";
import Play from "./pages/play";
import LazyLoad from "react-lazyload";
import { initGA } from "./GoogleAnalytics";

function App() {
  const [play, setPlay] = useState(false);
  const [playTrailer, setPlayTrailer] = useState(false);

  useEffect(() => {
    // Initialize Google Analytics with your tracking ID
    initGA("G-386Y20X7BQ");
    // Log the page view on component mount
  }, []);

  return (
    <>
      {play && (
        <LazyLoad>
          <Play setPlay={setPlay} videoID={707604096} />{" "}
        </LazyLoad>
      )}

      {playTrailer && (
        <LazyLoad>
          <Play setPlay={setPlayTrailer} videoID={898838700} />{" "}
        </LazyLoad>
      )}

      {!play && !playTrailer && (
        <Home setPlayTrailer={setPlayTrailer} setPlay={setPlay} />
      )}
    </>
  );
}

export default App;
