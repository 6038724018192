import React, { useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import { motion } from "framer-motion";
import { FadeLoader } from "react-spinners";

const Play = ({ setPlay, videoID }) => {
  const [vimeoLoaded, setVideoLoaded] = useState(false);

  return (
    <div className=" w-screen h-screen font-Trebuchet mx-auto relative flex flex-col justify-center items-center self-center object-center">
      {!vimeoLoaded && (
        <div className="absolute mx-auto h-full w-20 flex items-center justify-center">
          <FadeLoader color="#F90000" />
        </div>
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 2 } }}
        className={({ vimeoLoaded } ? "block" : "hidden") + " w-full z-30"}
      >
        <Vimeo
          video={videoID}
          responsive
          onLoaded={() => setVideoLoaded(true)}
          onReady={() => setVideoLoaded(true)}
        />
      </motion.div>

      <div className="text-theme-white-500 absolute z-50 bottom-1 text-xs text-center 2xl:text-base opacity-50">
        <span className="text-theme-red-500">Warning:</span> This Film Contains
        Profanity, Nudity, Drug/Alcohol Use, and Violence. Viewer discretion is
        advised.
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 2 } }}
        className="absolute z-50 top-4 right-4 font-light text-theme-white-500 hover:text-theme-red-500 cursor-pointer opacity-80 hover:opacity-100"
        onClick={() => setPlay(false)}
      >
        BACK
      </motion.div>

      <div className="absolute z-20 bg-grain-background bg-cover w-screen h-screen mix-blend-screen opacity-20 contrast-[1.2]"></div>
    </div>
  );
};

export default Play;
